import React from 'react';
import logo from "../../assets/images/first-trade.jpg";


const Logo = () => {
  return (
    <div>
        <div>
            <img className='w-44' src={logo} alt="first-trade" />
        </div>
    </div>
  )
}

export default Logo;